<script lang="ts" setup>
import {
  useSeoKit,
  useNuxtApp,
  defineAsyncComponent,
  computed,
  useAirpazCookie,
  onMounted,
  useRoute,
  useRouteBaseName,
  useNavigation,
  useConfigStore,
  useTrafficCookie,
  useToast,
  useI18n,
  useLiveChat,
  useUrlOrigin,
  useSchemaOrg,
  onBeforeRouteLeave,
  safeBtoa,
} from '#imports';

const { $device } = useNuxtApp();
const { t } = useI18n();
const urlOrigin = useUrlOrigin();

const view = $device.isMobile
  ? defineAsyncComponent(() => import('~/views/home/home.mobile.vue'))
  : defineAsyncComponent(() => import('~/views/home/home.desktop.vue'));

const trafficCookie = useTrafficCookie();
const route = useRoute();
const { navigationPath } = useNavigation();
const getRouteBaseName = useRouteBaseName();
const feedbackCookies = useAirpazCookie('feedback_submit');
const { code, state, src, error } = route.query;

onBeforeRouteLeave((to) => {
  if (getRouteBaseName(to) === 'feedback' && feedbackCookies.value) {
    useToast().show({
      type: 'error',
      message: t('csat.maximumdaylimit'),
    });
    return false;
  }
});

useSeoKit({
  titleTemplate: 'Airpaz - %s',
  meta: [
    {
      name: 'yandex-verification',
      content: 'b6f87296ad5891db',
    },
    {
      name: 'naver-site-verification',
      content: '00bd8163592ccade0f71ea590875adb732543a77',
    },
  ],
});

const isWechatOrder = computed(() => {
  return trafficCookie.affiliate.value?.id === 'WECHAT' && $device.isMobile;
});

function wechatRedirect() {
  const urlredirect = navigationPath('/');

  const wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx477b97b3a38e643f&redirect_uri=${urlredirect}&response_type=code&scope=snsapi_userinfo&state=wechat&connect_redirect=1#wechat_redirect`;

  return window.location.assign(wechatUrl);
}

function setCookieWechat() {
  const EXPIRY_TIME = 60 * 60 * 24; // 1 day
  const value = {
    code,
    state,
  };
  const bufferValue = safeBtoa(JSON.stringify(value));
  const wechatCookies = useAirpazCookie('wechat_params', { maxAge: EXPIRY_TIME });

  useConfigStore().currency = 'MYR';

  wechatCookies.value = bufferValue;
}

onMounted(() => {
  if (isWechatOrder.value && src === '9grid') {
    wechatRedirect();
  }

  if (isWechatOrder.value && !!state && !!code) {
    setCookieWechat();
  }

  if (!!error && error === 'NEWSLETTER_ERROR') {
    useToast().show({
      type: 'error',
      message: t('newsletter.failed'),
    });
  }
});

useLiveChat();

useSchemaOrg([
  defineBreadcrumb({
    itemListElement: [{ item: `${urlOrigin}${navigationPath('/')}`, name: t('global.home') }],
  }),
]);
</script>

<template>
  <Component :is="view" />
</template>

<style scoped></style>
